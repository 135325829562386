import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { useSession } from "../util/context.js";
import { FirebaseContext } from "gatsby-plugin-firebase";
import Spinner from "../components/spinner.js";
import constants from "../constants";

export default (props) => {
  const fb = React.useContext(FirebaseContext);
  const { user } = useSession();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [completed, setCompleted] = React.useState(false);
  const loc = useLocation();
  const q = new URLSearchParams(loc.search);
  const from = q.get("from");
  const title = q.get("title");
  const id = q.get("id");

  const submitRefund = async () => {
    if (!fb || !id) return;

    setLoading(true);
    setError("");

    if (!user) {
      setError("You must be logged in before making this request");
      setLoading(false);
      return;
    }

    try {
      const resp = await fb
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("refunds")
        .doc(id)
        .set({ submittedAt: new Date() }, { merge: true });
      setCompleted("Request submitted, I'll get back to you via email shortly");
    } catch (err) {
      setError(
        `Either you've already submitted this request or you don't have permission to do so. If this is a mistake email at ${constants.EMAIL}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md h-screen m-auto flex-center">
      <div className="bg-white relative border-t-4 border-red-400 rounded-lg shadow-2xl pt-8 pb-10 px-4 sm:px-8 text-center max-w-sm flex flex-col items-center">
        <Link
          replace={true}
          to={!!from ? from : "/"}
          className="absolute right-0 top-0 mt-2 mr-2 text-gray-500 rounded-full bg-gray-100 hover:bg-gray-200 flex-center h-6 w-6"
        >
          <svg
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </Link>
        <div className="rounded-full bg-red-200 flex-center h-12 w-12">
          <svg
            className="h-6 w-6 text-red-900"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"
            />
          </svg>
        </div>
        <h1 className="my-3 font-semibold text-lg">Sorry to See You Go :(</h1>
        <p className="text-sm text-gray-800 px-1 my-2">
          If there is anything I can improve let me know on Discord or at{" "}
          <strong>{constants.EMAIL}</strong>
        </p>
        <p className="text-sm pt-3">
          You are about to cancel <br />
          <span className="text-lg font-semibold">{title}</span>
          {user && (
            <>
              <br /> for <span className="font-medium">{user.email}</span>
            </>
          )}
        </p>
        <Link
          replace={true}
          to={!!from ? from : "/"}
          className={`w-full mt-4 py-3 px-8 font-medium text-white rounded bg-gray-800 focus:bg-gray-900 hover:bg-gray-700
focus:outline-none focus:shadow-outline text-sm leading-5`}
        >
          Go Back
        </Link>
        <button
          disabled={completed}
          onClick={submitRefund}
          className={`${
            completed
              ? "cursor-not-allowed text-gray-400 border-red-100"
              : "text-gray-900 border-red-300 hover:border-red-700 "
          } 
                    ${error ? "shake" : ""}
                    relative w-full mt-4 py-3 px-8 font-medium border rounded
focus:outline-none focus:shadow-outline text-sm leading-5`}
        >
          {loading ? (
            <div className="py-2">
              <Spinner loading={loading} />
            </div>
          ) : (
            <span>{completed ? "Submitted" : "Submit Refund Request"}</span>
          )}
        </button>
        {error && (
          <span className="text-left text-xs font-medium text-red-500 pt-2 italic">
            {error}
          </span>
        )}
        {completed && (
          <span className="text-left text-xs font-medium text-teal-500 pt-2 italic">
            {completed}
          </span>
        )}
      </div>
    </div>
  );
};
